function ExperimentUploaderFactory($rootScope, jobSubmit, $timeout, $http, $log, $q, $window, Upload, userTokens,
  SETTINGS) {

  const _url = SETTINGS.apiEndpoint + 'jobs/experiment';

  const experimentUploader = {
    allUploadedEvent: 'sq.jobs.upload.service:experimentUploadsComplete',
    running: false,
    file: null,
  };

  experimentUploader.listen = listen;

  experimentUploader.upload = (file, parentJobData, experimentName) => {
    experimentUploader.file = file;

    if (!file) {
      const err = new Error('File must be provided to Uploader');
      $log.error(err);
      return $q.reject(err);
    }

    if (!parentJobData || typeof parentJobData !== 'object') {
      const err = new Error('parentJob object required');
      $log.error(err);
      return $q.reject(err);
    }

    const deferred = $q.defer();
    experimentUploader.running = true;

    file.upload = Upload.upload({
      url: _url,
      data: {
        file,
        experimentName: experimentName,
      },
      headers: {
        Authorization: `Bearer ${userTokens.accessToken}`,
      }
    });

    file.upload.then((response) => {
      file.data = response.data;
      file.progress = 100;
    }, (rejection) => {
      if (rejection.status > 0) {
        $log.warn(`${rejection.status}: ${rejection.data.error}`);
        file.serverError = `Upload Failed Due To Input Error: ${rejection.data.error}`;
      }
      file.progress = 0;
    },
      (event) => {
        $rootScope.$evalAsync(() => {
          $window.requestAnimationFrame(() => {
            file.progress = event.loaded * 100 / event.total;
          });
        });
      }).catch((err) => {
        $log.error(err);
        file.progress = 0;
        file.serverError = `Upload Failed Due To Server Error: ${err}`;
      }).finally(() => {
        deferred.resolve();
        $rootScope.$broadcast(experimentUploader.allUploadedEvent);
        experimentUploader.running = false;
        experimentUploader.file = null;
      });

    return deferred.promise;
  };

  experimentUploader.cancelUpload = () => {
    if (experimentUploader.file) {
      experimentUploader.file.abort();
    }

    experimentUploader.file = null;
    experimentUploader.tsvData = [];
    experimentUploader.tsvHeaders = [];
    experimentUploader.experimentName = '';
  };

  function _setSocketIOlisteners(socket) {
    $log.debug('setting socket io listeners in jobs.upload.service.js');

    let timeoutPromise;
    socket.on(socketEvent, (response) => {
      $rootScope.$evalAsync(() => {
        const data = JSON.parse(response);
        $window.requestAnimationFrame(() => {
          experimentUploader.file.progress = data.data.loaded * 100 / data.data.total;
        });
      });
    });
  }

  function _removeListeners(socket) {
    socket.removeAllListeners(socketEvent);
  }


  function listen() {
    $rootScope.$on('sq.services.socketIO:unauthorized', (event, socket) => {
      $log.debug('sq.services.socketIO:unauthorized in job.experiment.upload.service');
      _removeListeners(socket);
    });

    $rootScope.$on('sq.services.socketIO:authenticated', (event, socket) => {
      $log.debug('sq.services.socketIO:authenticated in job.experiment.upload.service');
      _removeListeners(socket);
      _setSocketIOlisteners(socket);
    });

    $rootScope.$on('sq.services.socketIO:disconnected', (event, socket) => {
      $log.debug('sq.services.socketIO:disconnected in job.experiment.upload.service');

      _removeListeners(socket);
    });
  }

  return experimentUploader;
}

angular.module('sq.jobs.experiment.upload.service', ['ngFileUpload', 'sq.user.auth.tokens',
  'sq.jobs.submit.service',
  'sq.config'])
  .factory('ExperimentUploader', ExperimentUploaderFactory);